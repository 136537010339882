<template>
  <div class="overflow-auto flex flex-row items-start pb-1 select-none md:items-center md:px-3 md:gap-1">
    <div
      :class="{ activeClass: currentIndex == item.index }"
      v-for="(item, index) in tabs"
      :key="item.label"
      @click="handleClick(item.index)"
      class="flex flex-col-reverse md:flex-row tab items-center md:justify-between w-full md:w-auto md:mr-4">
        <span v-if="isMobileScreen" class="text-xs md:text-xs lg:text-sm text-center wrap-text">{{ item.label }}</span>
        <span v-else class="text-xs md:text-xs lg:text-sm text-center md:mr-2 wrap-text ">{{ item.label }}</span>
        <!-- <span class="text-xs md:text-xs lg:text-sm text-center md:mr-2">{{ item.label }}</span> -->
        <div class="count h-7 w-7 flex justify-center items-center">
          <div class="font-bold text-xs md:text-xs lg:text-sm">{{ item.count }}</div>
        </div>
      
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: ["tabs", "currentIndex"],
  data() {
    return {
      currentIndexSeleted:this.currentIndex,
    };
  },
  methods: {
    handleClick(index) {
      this.$emit("updateIndex", index);
    },
    handleChange(option){
     this.currentIndexSeleted=option;
      this.$emit("updateIndex", option);
    }
  },
  computed: {
    ...mapState(["userInfo","screenType"]),
    isMobileScreen(){
      return this.screenType?.isMobileScreen??false;
    }
  }
};
</script>
<style lang="scss" scoped>
.activeClass {
  color: $primaryColor;
}
.tab {
  cursor: pointer;
  span {
    line-height: 18px;
    color: #49454F;
    font-weight: 500;
  }
  .count {
    line-height: 28px;
    text-align: center;
    border-radius: 10px;
    color: #ffffff;
    background: #49454F;
    min-width: 35px;
  }

  .count-word {
    width: 110px;
  }
  min-width: 100px;
}
.activeClass {
  span {
    color: $primaryColor;
  }
  .count {
    background: $primaryColor;
    color: white;
  }
}
@media (max-width: 630px){
  .tab {
    min-width: fit-content;
  }
  .wrap-text {
    width: 90px;
    word-break: break-word;
    white-space: normal;
  } 
}
</style>
