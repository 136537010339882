import request from "@/services/request";
import moment from "moment";
import axios from "axios";
import { getToken } from "@/utils/auth";
import { Message } from "element-ui";

// 不合格的船管理
// 新增不合格的船
export function addUnqualifiedShips(data) {
  return request({
    url: "/jput/ships",
    method: "post",
    data,
  });
}
// 修改不合格的船
export function editUnqualifiedShips(data) {
  return request({
    url: "/jput/ships",
    method: "put",
    data,
  });
}
// 获取不合格的船列表
export function getUnqualifiedShips(data) {
  return request({
    url: "/jput/ships/list",
    method: "get",
    data,
  });
}
// 删除不合格的船
export function deleteUnqualifiedShips(ids) {
  return request({
    url: "/jput/ships/" + ids,
    method: "delete",
  });
}
// 码头管理
// 列表
export function getTankList(params) {
  return request({
    url: "/jput/berth/list",
    method: "get",
    params,
  });
}
// 码头使用情况
export function getUseBerth(cancelToken) {
  return request({
    url: "/jput/berth/getUseBerth",
    cancelToken
  });
}
// 已存在的公司列表
export function getCompanyList() {
  return request({
    url: "/jput/nomination/getCustomerCompany",
  });
}
//新增
export function addTank(data) {
  return request({
    url: "/jput/berth",
    method: "post",
    data,
  });
}
// 修改
export function editTank(data) {
  return request({
    url: "/jput/berth",
    method: "put",
    data,
  });
}
export function deleteTank(ids) {
  return request({
    url: "/jput/berth/" + ids,
    method: "delete",
  });
}
export function detailTank(id) {
  return request({
    url: "/jput/berth/" + id,
    method: "get",
  });
}
// uploadFile
export function uploadFile(file) {
  const formdata = new FormData();
  formdata.append("file", file);
  return request({
    url: "/common/upload",
    method: "post",
    data: formdata,
  });
}
// 不合格的船管理
// 新增不合格的船
export function getUnqualifiedShipsList(params) {
  return request({
    url: "/jput/ships/list",
    method: "get",
    params,
  });
}
// 修改不合格的船
export function editUnqualifiedShipsList(data) {
  return request({
    url: "/jput/ships/list",
    method: "put",
    data,
  });
}
// 删除不合格的船
export function deleteUnqualifiedShipsList(ids) {
  return request({
    url: "/jput/ships/" + ids,
    method: "delete",
  });
}
// 储罐管理
// 添加油罐
export function addTanks(data) {
  return request({
    url: "/jput/tank",
    method: "post",
    data,
  });
}
// 修改油罐
export function editTanks(data) {
  return request({
    url: "/jput/tank",
    method: "put",
    data,
  });
}
// 油罐列表
export function getTanksList(params) {
  return request({
    url: "/jput/tank/list",
    params,
  });
}
// 上传文件
export function singleleUploadFile(file) {
  let formdata = new FormData();
  formdata.append("file", file);
  return request({
    url: "/common/upload",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    method: "post",
    data: formdata,
  });
}
//储罐新增附件
export function tanksAddFiles(data) {
  return request({
    url: "/jput/file",
    method: "post",
    data,
  });
}
// 全部公司列表
export function allCompanyList(params) {
  return request({
    url: "/jput/company/companyList",
    method: "get",
    params,
  });
}
// 储罐与公司关联
export function companyLink(data) {
  return request({
    url: "/jput/company",
    method: "post",
    data,
  });
}
// 驳船附件
// link 新增驳船附件
export function addBoatLink(data) {
  return request({
    url: "/jput/link",
    method: "post",
    data,
  });
}
// 驳船附件列表
export function boatListFile(params) {
  return request({
    url: "/jput/link/list",
    method: "get",
    params,
  });
}
// 删除驳船附件
export function deleteBoatFile(ids) {
  return request({
    url: "/jput/link/" + ids,
    method: "delete",
  });
}
// 储罐列表
export function getCQList(id) {
  return request({
    url: "/jput/file/getCQList/" + id,
  });
}
// COQ列表
export function getCoqList(params) {
  return request({
    url: "/jput/quantity/list",
    params,
  });
}
// BL列表
export function getBlList(params) {
  return request({
    url: "/jput/billOfLading/list",
    params,
  });
}
// barge签名
export function bargeSign(data) {
  return request({
    url: "/jput/nomination/bargeSign",
    method: "post",
    data,
  });
}
// jupt签名
export function juptSign(data) {
  return request({
    url: "/jput/nomination/jputSign",
    method: "post",
    data,
  });
}
// 驳船首页数据
export function getBoadDashboardList() {
  return request({
    url: "/jput/nomination/dashboard",
  });
}
//coq list
export function getCoqMangeListList(params) {
  return request({
    url: "/jput/quantity/list",
    params,
  });
}
export function getBillOfLadingList(params) {
  return request({
    url: "/jput/billOfLading/list",
    params,
  });
}
// 测量公司的tank
export function getMyCompanyTank(params) {
  return request({
    url: "/jput/tank/getMyCompanyTank",
    params,
  });
}
// barge获取Stamp
export function getBargeStamp(data) {
  return request({
    url: "/jput/nomination/getBargeStamp",
    method: "post",
    data,
  });
}
// 签名图片上传
export function uploadEsignature(data) {
  return request({
    url: "/jput/nomination/uploadESignature",
    method: "post",
    data,
  });
}
// 重置码头分配
export function resetBerth(params) {
  return request({
    url: "/jput/nomination/resetBerth",
    method: "get",
    params,
  });
}
// reset berth add reason
export function resetBerthReason(data) {
  return request({
    url: "/jput/nomination/resetBerth",
    method: "post",
    data,
  });
}
// complete barging normination
export function completeBargingNormination(id) {
  return request({
    url: `/jput/nomination/${id}/complete`,
    method: "put",
  });
}
// juput reset
export function juptResetSign(data) {
  return request({
    url: "/jput/nomination/jputResetSign",
    method: "post",
    data,
  });
}
// getBerth
export function getBerth() {
  return request({
    url: "/jput/berth/getBerth",
  });
}
export function getRequestNum() {
  return request({
    url: "/jput/request/selectRequestNum",
  });
}
// getSurveyo options
export function getSurveyoOptions() {
  return request({
    url: "/system/user/getSurveyor",
  });
}
// get audiolog list
export function getAuditLogList(params) {
  return request({
    url: "/jput/log/list",
    method: "get",
    params,
  });
}
// 查询barge是否合适
export function searchBargeBer(data){
  return request({
    url:"/jput/berth/dockAllBer",
    method:"post",
    data
  })
}
// get barge list
export function getBargeList() {
  return request({
    url: "/system/user/getBargesWithoutBargeOperator",
  });
}

// attach barge
export function attachBarge(data) {
  return request({
    url: `/system/user/attachBarge`,
    method: "put",
    data
  });
}

// detach barge
export function detachBarge(data) {
  return request({
    url: `/system/user/detachBarge`,
    method: "put",
    data
  });
}

export function getAttachedBargeList() {
  return request({
    url: "/system/user/getUserList",
  });
}

export function getNominationExcelList( params ) {
  return request({
    url: "/jput/nomination/excel/list",
    method: "get",
    params,
  });
}

export function exportNominationExcelList( params ) {
  const filename = "download-excel-" + moment().format("yyyyMMDD") + ".xls"
  return request({
    url: "/jput/nomination/excel/export",
    method: "get",
    responseType: 'arraybuffer',
    params,
  }).then(data => {
    downloadFile(data, filename)
  }).catch(() => Message({ message: 'Export excel failed', type: "error", duration: 15000, showClose: true }))
}

export function getPilotExcelList( params ) {
  return request({
    url: "/jput/nomination/pilot/excel/list",
    method: "get",
    params,
  });
}

export function exportPilotExcelList( params ) {
  const filename = "download-excel-" + moment().format("yyyyMMDD") + ".xls"
  return request({
    url: "/jput/nomination/pilot/excel/export",
    method: "get",
    responseType: 'arraybuffer',
    params,
  }).then(data => {
    downloadFile(data, filename)
  }).catch(() => Message({ message: 'Export excel failed', type: "error", duration: 15000, showClose: true }))
}

export function addToNominationExcelList(data) {
  return request({
    url: "/loaded/save",
    method: "post",
    data,
  });
}

export function downloadFile( arrayBuffer, filename ) {
  filename = filename ?? 'Download.txt'
  const url = window.URL.createObjectURL(new Blob([arrayBuffer]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export function getMooringExcelList( params ) {
  return request({
    url: "/jput/nomination/mooringReport/excel/list",
    method: "get",
    params,
  });
}

export function exportMooringExcelList( params ) {
  const filename = "download-excel-" + moment().format("yyyyMMDD") + ".xls"
  return request({
    url: "/jput/nomination/mooringReport/excel/export",
    method: "get",
    responseType: 'arraybuffer',
    params,
  }).then(data => {
    downloadFile(data, filename)
  }).catch(() => Message({ message: 'Export excel failed', type: "error", duration: 15000, showClose: true }))
}

export function getCompletedCoqList( params ) {
  return request({
    url: "/jput/link/completed-nomination-links",
    method: "get",
    params,
  });
}

export function removeLogisticsExcelCallibrationRecord(id){
  return request({
    url: "/loaded/delete/" + id,
    method: "delete",
  });
}