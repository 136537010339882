<template>
  <div class="overflow-hidden" style="padding:20px 0px; background-color: white; border-radius: 10px;">
    <div class="flex justify-end">
      <div class="flex p-1 laptop:pb-2 desktop:pb-8" style="width: 500px">
        <el-input
          class="Comipt"
          size="small"
          :placeholder="$t('btn.search')"
          v-model="searchValue"
          @keyup.enter.native="getList"
        >
          <span slot="suffix" @click="getList">
            <svg-icon iconClass="search" />
          </span>
        </el-input>
        <el-select
          style="padding-left: 20px; width: 100%"
          size="small"
          :clearable="true"
          v-model="selectDate"
          @change="handleSelectChange"
          :placeholder="$t('btn.Today')"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="flex justify-center">
      <div class="w-full">
        <el-table
          class="Comtable"
          :data="listData"
        >
          <el-table-column align="left" prop="userName" label="Username" />
          <el-table-column
            align="left"
            prop="userEmailAddress"
            label="Email Address"
          />
          <el-table-column align="left" prop="role" label="Role" />
          <el-table-column align="left" prop="action" label="Action" />
          <el-table-column align="left" prop="createTime" label="Datetime">
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="flex justify-end w-full">
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="pageParams.pageNum"
        :limit.sync="pageParams.pageSize"
        @pagination="getList"
      ></pagination>
    </div>
  </div>
</template>

<script>
import tabs from "@/components/tabs.vue";
import { getAuditLogList } from "@/services";
const rolesMap = {
  0: "Admin",
  1: "Terminal Logistics",
  2: "Terminal Ops",
  3: "Terminal Team Leader",
  4: "Company Admin",
  5: "Customer User",
  6: "Surveyor Admin",
  7: "Surveyor User",
  8: "Barge",
};
export default {
  name: "requests",
  components: { tabs },

  data() {
    return {
      tabsList: [
        { label: "Unlink", count: 0, index: "0" },
        { label: "Linked", count: 0, index: "1" },
      ],
      upIndex: 0,
      listData: [],
      searchValue: "",
      options: [
        {
          value: "1",
          label: "Today",
        },
        {
          value: "2",
          label: "ThisWeek",
        },
        {
          value: "3",
          label: "ThisMonth",
        },
        {
          value: "4",
          label: "ThisYear",
        },
      ],
      selectDate: "",
      pageParams: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      operationData: {
        src: "",
        show: false,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    updateIndex(index) {
      this.upIndex = index;
      this.getList();
    },
    handleSelectChange() {
      this.getList();
    },
    getList() {
      let data = {
        ...this.pageParams,
      };
      if (this.selectDate) {
        data["searchDateType"] = this.selectDate;
      }
      if (this.searchValue) {
        data["userName"] = this.searchValue;
        this.searchValue = "";
      }

      getAuditLogList(data).then((res) => {
        this.listData = res.rows.map((item) => {
          return {
            ...item,
            role: rolesMap[item.role],
          };
        });
        this.total = res.total;
      });
    },
    getCellStyle({ rowIndex }) {
      if (rowIndex % 2 === 1) {
        return {
          backgroundColor: "rgba(255,255,255,0.2)",
        };
      } else {
        return {
          backgroundColor: "rgba(228,228,228,0.2)",
        };
      }
    },
  },
};
</script>

<style scoped lang="scss">
.Topinp {
  position: relative;
}
.Topinput {
  display: flex;
  position: absolute;
  top: -65px;
  right: -40px;
  .Comipt {
    width: 275px;
    height: 46px;
  }
  .Pendingselect {
    width: 221px;
    height: 46px;
    margin-left: 16px;
  }
}
.Reqcol {
  display: flex;
  justify-content: center;
  height: calc(90vh - 80px - 32 * 2px - 56px);
  overflow-y: scroll;
  .ReqTable {
    width: 1000px;
  }
  .el-button--default {
    color: #fff;
    background-color: #008CDB;
  }
  .el-button--default.is-plain:hover {
    color: #fff;
    background: #008CDB;
    border-color: #008CDB;
  }
  .el-button--default:focus {
    color: #fff !important;
    background: #008CDB !important;
    border-color: #008CDB;
  }
}
::v-deep {
  .el-input__icon.el-icon-search {
    height: 32px !important;
  }
}
::v-deep .pagination-container {
  background: #f2f7fa;
}
::v-deep .el-input__suffix-inner {
  padding-right: 5px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  svg {
    width: 13px;
    height: 13px;
    cursor: pointer;
  }
}
::v-deep .pagination-container {
  padding: 20px 0 0 0;
}
</style>
